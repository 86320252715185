import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import Wrapper from '../../components/layout/Wrapper/Wrapper';
import { Stack, Typography } from '@mui/material';
import DataTable from '../../components/datatable/Datatable';
import DeleteDrawer from '../../components/common/DeleteDrawer';
import { headCells } from './constants/columns';
import { IHistory } from '../../types/organisations-types';
import FluxSelect from '../../components/common/FluxSelect';
import CustomDatePicker from '../../components/common/DatePicker';
import { IHistoryResponse } from '../../types/history-types';
import { CrudOptions } from '../../types/datatable-types';
import { convertDateToDifferentFormat } from '../../utils/helpers';

const HistoryList = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const { history } = useSelector((state: RootState) => state.history);

	const [historyList, setHistoryList] = React.useState(history);
	const [selectedFilterFlux, setSelectedFilterFlux] = React.useState<string>('All');

	const [openDeleteDrawer, setOpenDeleteDrawer] = React.useState(false);

	const [selectedHistory, setSelectedHistory] = React.useState<IHistory>();

	const [page, setPage] = React.useState(0);
	const [rowPerPage, setRowPerPage] = React.useState(5);
	const [totalItemsCount, setTotalItemsCount] = React.useState(0);

	const [date, setDate] = React.useState<string | null>(null);

	React.useEffect(() => {
		const payload: { page: number; pageSize: number; date?: string | null } = {
			page: page + 1,
			pageSize: rowPerPage,
			date: date || convertDateToDifferentFormat(new Date(), 'YYYY-MM-DD'),
		};

		dispatch.history.getAll(payload).then((res: IHistoryResponse) => {
			setTotalItemsCount(res?.totalItemsCount);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rowPerPage, page, date]);

	React.useEffect(() => {
		if (selectedFilterFlux === 'All') {
			setHistoryList(history);
		} else {
			setHistoryList(history?.filter((f) => f.name === selectedFilterFlux));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedFilterFlux, history]);
	
	const crudHandlers: CrudOptions = {
		handleArchive: (id: number) => {
			setOpenDeleteDrawer(true);
			const historyFinded = history.find((hist) => hist.id === id);
			setSelectedHistory(historyFinded);
		},
	};

	const handleDelete = () => {
		dispatch.history
			.delete({ id: selectedHistory?.id, page: page + 1, pageSize: rowPerPage, date })
			.then(() => {
				setOpenDeleteDrawer(false);
			});
	};

	return (
		<Wrapper>
			<Stack direction='row' justifyContent='space-between' sx={{ marginBottom: '35px' }}>
				<Typography variant='h1'>{t('historique.Historique')}</Typography>
				<Stack direction={{ xs: 'column', sm: 'row' }} gap={2}>
					<CustomDatePicker
						date={date}
						setDate={setDate}
						placeholder='Sélectionner une date'
					/>
					<FluxSelect
						selectedFlux={selectedFilterFlux}
						setSelectedFlux={setSelectedFilterFlux}
					/>
				</Stack>
			</Stack>
			<DataTable
				columns={headCells}
				data={historyList}
				filter={selectedFilterFlux !== 'All'}
				defaultSortId='name'
				crudOptions={crudHandlers}
				currentPage={page}
				setCurrentPage={setPage}
				totalPageCount={totalItemsCount}
				rowPerPage={rowPerPage}
				setRowPerPage={setRowPerPage}
				translation='historique'
				noDataText='Aucune donnée disponible avec la date sélectionnée'
			/>
			{selectedHistory && (
				<DeleteDrawer
					open={openDeleteDrawer}
					setOpen={() => {
						setSelectedHistory(undefined);
						setOpenDeleteDrawer(false);
					}}
					handleDelete={handleDelete}
					type={t('historique.Historique')}
					data={{
						name: selectedHistory.name,
						id: selectedHistory.id,
					}}
				/>
			)}
		</Wrapper>
	);
};

export default HistoryList;
