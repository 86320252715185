import * as React from 'react';
import {
	AppBar,
	Badge,
	Box,
	IconButton,
	Menu,
	MenuItem,
	Stack,
	Toolbar,
	Typography,
} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import logo from '../../../assets/img/logo-white.png';
import {
	LogoutOutlined,
	AccountCircle,
	AccountCircleOutlined,
	Menu as MenuIcon,
} from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import theme from '../../../styles/theme';
import { useLocation, useNavigate } from 'react-router-dom';
import { menu, notificationsPages } from '../../../routes/menu';
import LanguageSelector from './LanguageSwitch';
import { useTranslation } from 'react-i18next';
import useWebSocket from '../../../hooks/useWebSocket';
import { IVideo } from '../../../types/flux-types';
interface INotificationData {
	notif_id: number;
	detection_id: number;
	video_id: number;
	detection_type: string;
	updated_stream: IVideo;
}

const Header = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { pathname } = useLocation();

	const { email, id, open } = useSelector((state: RootState) => state.auth);
	const { count } = useSelector((state: RootState) => state.notifications);
	const { flux } = useSelector((state: RootState) => state.flux);

	// Fonction de gestion de la réception des messages WebSocket
	const handleMessage = (event: MessageEvent) => {
		const data: INotificationData = JSON.parse(event.data);
		const { notif_id, detection_type, updated_stream } = data;
		if (notif_id) {
			dispatch.alerts.setAlert({
				open: true,
				severity: 'error',
				message: t('alertes.urgent', { detection_type }),
				action: `../${notificationsPages.read.path}/${notif_id}`,
			});
			dispatch.notifications.getAll();
		}
		if (updated_stream && pathname === menu.flux.path) {
			console.log('we are on gestion-flux page and stream updated exists.');

			const newFluxData = flux.map((item) => {
				// Trouver les correspondances par 'id'
				const updatedItem = updated_stream.id === item.id;
				// Si une correspondance est trouvée, fusionner les données
				return updatedItem ? { ...item, updatedItem } : item;
			});
			dispatch.flux.set(newFluxData, 'flux');
			dispatch.flux.getLiveStream();
		}
	};

	const { isConnected } = useWebSocket({
		url: `notification/${id}`,
		onMessage: handleMessage,
	});
	console.log('is Notification Connected', isConnected);

	React.useEffect(() => {
		dispatch.notifications.getAll();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

	const handleLogout = () => {
		dispatch.auth.logout(true);
	};

	const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<AppBar
			id='header'
			position='fixed'
			sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, boxShadow: 'none' }}
			style={{ backgroundColor: theme.palette.text.primary }}>
			<Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
				<Box sx={{ display: 'flex', flexWrap: 'nowrap' }}>
					<IconButton edge='start' color='inherit' aria-label='menu'>
						<img src={logo} alt='logo-fire-eagle' style={{ height: 90 }} />
					</IconButton>
					<IconButton
						onClick={() => dispatch.auth.setOpen(!open)}
						size='large'
						edge='start'
						color='inherit'
						aria-label='menu'
						sx={{ ml: 2, display: { sm: 'flex', md: 'none' } }}>
						<MenuIcon />
					</IconButton>
				</Box>
				<div>
					<Stack direction='row'>
						<IconButton
							onClick={() => navigate(`../${notificationsPages.see.path}`)}
							size='large'
							aria-controls='menu-appbar'
							aria-haspopup='true'
							color={count > 0 ? 'primary' : 'inherit'}>
							<Badge color='error' badgeContent={count}>
								<NotificationsIcon style={{ width: 20, height: 20 }} />
							</Badge>
						</IconButton>

						<Stack
							direction='row'
							alignItems='center'
							onClick={handleMenu}
							sx={{ cursor: 'pointer' }}>
							<IconButton
								size='large'
								aria-controls='menu-appbar'
								aria-haspopup='true'
								color='inherit'>
								<AccountCircle style={{ width: 20, height: 20 }} />
							</IconButton>
							<Badge variant='dot' color='success' sx={{ paddingRight: '5px' }}>
								<Typography
									variant='body2'
									sx={{
										fontWeight: 300,
										display: { sm: 'none', xs: 'none', md: 'flex' },
									}}>
									{email}
								</Typography>
							</Badge>
						</Stack>

						<LanguageSelector />
					</Stack>
					<Menu
						id='menu-appbar'
						anchorEl={anchorEl}
						keepMounted
						open={Boolean(anchorEl)}
						onClose={handleClose}>
						<MenuItem
							TouchRippleProps={{
								classes: {
									ripple: 'custom-ripple',
								},
							}}
							onClick={handleClose}>
							<AccountCircleOutlined
								style={{ width: 30, height: 30, marginRight: 15 }}
							/>
							<Typography>{t('common.Mon Profil')}</Typography>
						</MenuItem>
						<MenuItem
							TouchRippleProps={{
								classes: {
									ripple: 'custom-ripple',
								},
							}}
							onClick={handleLogout}>
							<LogoutOutlined
								style={{ width: 30, height: 30, marginRight: 15 }}
								color='error'
							/>
							<Typography color='error'>{t('common.Se Déconnecter')}</Typography>
						</MenuItem>
					</Menu>
				</div>
			</Toolbar>
		</AppBar>
	);
};

export default Header;
