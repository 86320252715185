import { Box, Button, IconButton, Stack, Typography, useMediaQuery } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Wrapper from '../../components/layout/Wrapper/Wrapper';
import AddIcon from '@mui/icons-material/Add';
import FluxSelect from '../../components/common/FluxSelect';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from '../../components/datatable/Datatable';
import { headCells } from './constants/columns';
import { IVideo } from '../../types/flux-types';
import DeleteDrawer from '../../components/common/DeleteDrawer';
import CreateUpdateFlux from './components/CreateUpdate';
import { RootState } from '../../store/store';
import { CrudOptions } from '../../types/datatable-types';
import { menu } from '../../routes/menu';
import { useNavigate } from 'react-router-dom';
import theme from '../../styles/theme';
import LiveStreams from './components/LiveStreams';
import { usePermissions } from '../../hooks/usePermissions';

const GestionFlux = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { hasPermission } = usePermissions();

	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const { flux } = useSelector((state: RootState) => state.flux);
	const [fluxList, setFluxList] = React.useState<IVideo[]>(flux);

	const [selectedFilterFlux, setSelectedFilterFlux] = React.useState<string>('All');

	const [selectedFlux, setSelectedFlux] = React.useState<IVideo>();
	const [openDeleteDrawer, setOpenDeleteDrawer] = React.useState(false);
	const [openCreateUpdateFluxDrawer, setOpenCreateUpdateFluxDrawer] = React.useState(false);

	React.useEffect(() => {
		dispatch.flux.getAll();
		dispatch.flux.getLiveStream();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		if (selectedFilterFlux === 'All') {
			setFluxList(flux);
		} else {
			setFluxList(flux?.filter((f) => f.name === selectedFilterFlux));
		}
	}, [flux, selectedFilterFlux]);

	const crudHandlers: CrudOptions = {
		handleEdit: hasPermission(['admin', 'superadmin'])
			? (id: number) => {
					setOpenCreateUpdateFluxDrawer(true);
					const fluxFinded = flux.find((f) => f.id === id);
					setSelectedFlux(fluxFinded);
				}
			: undefined,
		handleDelete: hasPermission(['admin', 'superadmin'])
			? (id: number) => {
					setOpenDeleteDrawer(true);
					const fluxFinded = flux.find((f) => f.id === id);
					setSelectedFlux(fluxFinded);
				}
			: undefined,
		handleSee: (id: number) => {
			navigate(`../${menu.flux.path}/${id}`);
		},
	};

	const handleDelete = () => {
		dispatch.flux.delete(selectedFlux?.id).then(() => {
			setOpenDeleteDrawer(false);
		});
	};

	return (
		<Wrapper>
			<LiveStreams />

			<Stack direction='row' justifyContent='space-between' sx={{ marginBottom: '35px' }}>
				<Typography variant='h1'>{t('flux.Gestion des flux')}</Typography>
				<Stack direction={{ sm: 'row' }} alignItems={{ sm: 'end', md: 'center' }} gap={1}>
					<FluxSelect
						selectedFlux={selectedFilterFlux}
						setSelectedFlux={setSelectedFilterFlux}
					/>

					{hasPermission(['admin', 'superadmin']) ? (
						isMobile ? (
							<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
								<IconButton
									aria-label='edit'
									onClick={() => setOpenCreateUpdateFluxDrawer(true)}
									sx={{
										backgroundColor: theme.palette.primary.main,
										border: `1px solid ${theme.palette.primary.main}`,
										borderRadius: '4px',
										'&.MuiIconButton-root:hover': {
											backgroundColor: '#F6B8A2',
											border: `1px solid #F6B8A2`,
										},
									}}>
									<AddIcon sx={{ color: '#fff' }} />
								</IconButton>
							</Box>
						) : (
							<Button
								sx={{ height: '40px' }}
								variant='contained'
								startIcon={<AddIcon />}
								onClick={() => setOpenCreateUpdateFluxDrawer(true)}>
								{t('flux.Ajouter un flux')}
							</Button>
						)
					) : null}
				</Stack>
			</Stack>

			<DataTable
				columns={headCells}
				data={fluxList}
				defaultSortId='name'
				crudOptions={crudHandlers}
				translation='flux'
			/>

			{selectedFlux && (
				<DeleteDrawer
					open={openDeleteDrawer}
					setOpen={() => {
						setSelectedFlux(undefined);
						setOpenDeleteDrawer(false);
					}}
					handleDelete={handleDelete}
					data={{
						name: selectedFlux.name,
						id: selectedFlux.id,
					}}
				/>
			)}

			<CreateUpdateFlux
				open={openCreateUpdateFluxDrawer}
				setOpen={() => {
					setSelectedFlux(undefined);
					setOpenCreateUpdateFluxDrawer(false);
				}}
				data={selectedFlux}
			/>
		</Wrapper>
	);
};

export default GestionFlux;
