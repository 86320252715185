import React from 'react';

export type SelectValues = {
	[fieldName: string]: string | number | (string | number)[];
};

// Hook personnalisé pour gérer plusieurs champs Select
export const useSelectField = () => {
	const [selectValues, setSelectValues] = React.useState<SelectValues>({});

	const handleSelectChange = (name: string, event: any, boolean = false) => {
		if (!boolean) {
			const {
				target: { value },
			} = event;

			setSelectValues((prevState) => ({
				...prevState,
				[name]: typeof value === 'string' ? value.split(',') : value,
			}));
		} else {
			const {
				target: { checked },
			} = event;
			setSelectValues((prevState) => ({
				...prevState,
				[name]: checked,
			}));
		}
	};

	const resetState = () => {
		setSelectValues({});
	};

	return {
		selectValues,
		handleSelectChange,
		resetState,
		setSelectValues,
	};
};
