import * as React from 'react';
import DataTable from '../../../components/datatable/Datatable';
import Wrapper from '../../../components/layout/Wrapper/Wrapper';
import { Box, Button, IconButton, Stack, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import AddIcon from '@mui/icons-material/Add';
import CreateUpdateInstance from './components/CreateUpdate';
import DeleteDrawer from '../../../components/common/DeleteDrawer';
import { headCells } from './constants/columns';
import { CrudOptions } from '../../../types/datatable-types';
import theme from '../../../styles/theme';
import { IInstance } from '../../../types/instances-types';

const InstancesList = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const { instances } = useSelector((state: RootState) => state.instances);

	const [openDeleteDrawer, setOpenDeleteDrawer] = React.useState(false);
	const [openCreateUpdateInstanceDrawer, setOpenCreateUpdateInstanceDrawer] =
		React.useState(false);

	const [selectedInstance, setSelectedInstance] = React.useState<IInstance>();

	React.useEffect(() => {
		dispatch.instances.getAll();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const crudHandlers: CrudOptions = {
		handleEdit: (id: number) => {
			setOpenCreateUpdateInstanceDrawer(!openCreateUpdateInstanceDrawer);
			const instance = instances.find((instance) => instance.id === id);
			setSelectedInstance(instance);
		},
		handleDelete: (id: number) => {
			setOpenDeleteDrawer(true);
			const instance = instances.find((instance) => instance.id === id);
			setSelectedInstance(instance);
		},
	};

	const handleDelete = () => {
		dispatch.instances.delete(selectedInstance?.id).then(() => {
			setOpenDeleteDrawer(false);
		});
	};

	return (
		<Wrapper>
			<Stack direction='row' justifyContent='space-between' sx={{ marginBottom: '35px' }}>
				<Typography variant='h1'>{t('instances.Liste des instances de calcul')}</Typography>

				{isMobile ? (
					<Box>
						<IconButton
							aria-label='create'
							onClick={() =>
								setOpenCreateUpdateInstanceDrawer(!openCreateUpdateInstanceDrawer)
							}
							sx={{
								backgroundColor: theme.palette.primary.main,
								border: `1px solid ${theme.palette.primary.main}`,
								borderRadius: '4px',
								'&.MuiIconButton-root:hover': {
									backgroundColor: '#F6B8A2',
									border: `1px solid #F6B8A2`,
								},
							}}>
							<AddIcon sx={{ color: '#fff' }} />
						</IconButton>
					</Box>
				) : (
					<Button
						onClick={() =>
							setOpenCreateUpdateInstanceDrawer(!openCreateUpdateInstanceDrawer)
						}
						sx={{ height: '40px' }}
						variant='contained'
						startIcon={<AddIcon />}>
						{t('instances.Créer une nouvelle instance')}
					</Button>
				)}
			</Stack>
			<DataTable
				columns={headCells}
				data={instances}
				defaultSortId='type'
				crudOptions={crudHandlers}
				translation='instances'
			/>
			{selectedInstance && (
				<DeleteDrawer
					open={openDeleteDrawer}
					setOpen={() => {
						setSelectedInstance(undefined);
						setOpenDeleteDrawer(false);
					}}
					handleDelete={handleDelete}
					data={{
						name: `${selectedInstance?.name} ${selectedInstance?.instance_id}`,
						id: selectedInstance.id,
					}}
				/>
			)}

			<CreateUpdateInstance
				open={openCreateUpdateInstanceDrawer}
				setOpen={() => {
					setSelectedInstance(undefined);
					setOpenCreateUpdateInstanceDrawer(false);
				}}
				data={selectedInstance}
			/>
		</Wrapper>
	);
};

export default InstancesList;
