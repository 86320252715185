import { createModel } from '@rematch/core';
import { RootModel } from '..';
import { API_ENDPOINT, httpSecured } from '../../services/http';
import { IUsersState } from '../../types/user-types';
import { splitAndParseNumbers } from '../../utils/helpers';

export const users = createModel<RootModel>()({
	state: {
		users: [],
	} as IUsersState,
	reducers: {
		set: (state, payload, subject) => {
			return { ...state, [subject]: payload };
		},
	},
	effects: (dispatch) => ({
		async getAll() {
			try {
				const { data, status } = await httpSecured.get(API_ENDPOINT.USER.GET_USERS);
				if (data) {
					this.set(data, 'users');
					return { data, status };
				}
			} catch (error: any) {
				console.error(error);
				if (error.response?.status === 401) {
					dispatch.auth.logout();
				}
				return error.response.data.error;
			}
		},
		async search(payload) {
			try {
				const { data, status } = await httpSecured.get(
					`${API_ENDPOINT.USER.SEARCH_USER}${payload.id}/?query=${payload.query}`,
				);
				if (data) {
					return { data, status };
				}
			} catch (error: any) {
				console.error(error);
				if (error.response?.status === 401) {
					dispatch.auth.logout();
				}
				return error.response.data.error;
			}
		},
		async createUser(payload) {
			try {
				const { data } = await httpSecured.post(`${API_ENDPOINT.USER.SIGNUP}`, payload);
				if (data) {
					dispatch.alerts.setAlert({
						open: true,
						severity: 'success',
						message: "L'utilisateur a été créé avec succès.",
					});
					this.getAll();
					return true;
				}
			} catch (error: any) {
				console.error(error);
				if (error.response?.status === 401) {
					dispatch.auth.logout();
				}
				dispatch.alerts.setAlert({
					open: true,
					severity: 'error',
					message:
						typeof error.response.data.error == 'string'
							? error.response.data.error
							: error.response.data.message ||
								"Une erreur est survenue lors de la création de l'utilisateur.",
				});
				return false;
			}
		},
		async delete(id) {
			try {
				await httpSecured.delete(`${API_ENDPOINT.USER.DELETE_USER}${id}/`);

				dispatch.alerts.setAlert({
					open: true,
					severity: 'success',
					message: "L'utilisateur a été supprimé avec succès.",
				});
				this.getAll();
				return true;
			} catch (error: any) {
				console.error(error);
				if (error.response?.status === 401) {
					dispatch.auth.logout();
				}
				dispatch.alerts.setAlert({
					open: true,
					severity: 'error',
					message:
						typeof error.response.data.error == 'string'
							? error.response.data.error
							: error.response.data.message ||
								"Une erreur est survenue lors de la suppression de l'utilisateur.",
				});
				return false;
			}
		},
		async update(payload) {
			try {
				if (typeof payload.formValues.organisation === 'string') {
					payload.formValues.organisation = splitAndParseNumbers(
						payload.formValues.organisation,
					);
				}

				const { data } = await httpSecured.put(
					`${API_ENDPOINT.USER.UPDATE_USER}${payload.id}/`,
					payload.formValues,
				);
				if (data) {
					dispatch.alerts.setAlert({
						open: true,
						severity: 'success',
						message: "L'utilisateur a été modifié avec succès.",
					});
					this.getAll();
					return true;
				}
			} catch (error: any) {
				console.error(error);
				if (error.response?.status === 401) {
					dispatch.auth.logout();
				}
				dispatch.alerts.setAlert({
					open: true,
					severity: 'error',
					message:
						typeof error.response.data.error == 'string'
							? error.response.data.error
							: error.response.data.message ||
								"Une erreur est survenue lors de la modification de l'utilisateur.",
				});
			}
		},
	}),
});
