import {
	Box,
	Button,
	FormControl,
	MenuItem,
	Select,
	SelectChangeEvent,
	Typography,
} from '@mui/material';
import React, { FC, useState } from 'react';
import CustomDrawer from '../../../components/common/CustomDrawer';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ILiveStream } from '../../../types/flux-types';
import { usePermissions } from '../../../hooks/usePermissions';

interface ISelectLiveStreamProps {
	row: ILiveStream;
}
const SelectLiveStream: FC<ISelectLiveStreamProps> = ({ row }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { hasPermission } = usePermissions();

	const [selectLiveStream, setSelectLiveStream] = useState(row?.currentStreamMaximum.toString());
	const [open, setOpen] = useState(false);
	const [time, setTime] = useState(0);

	const handleChange = (event: SelectChangeEvent) => {
		setSelectLiveStream(event.target.value);
		setOpen(true);
		dispatch.flux
			.processStream({
				n_streams: event.target.value,
				organisationId: row.organisation[0].id,
				only_estimation: true,
			})
			.then((res: { turn_on_time: number }) => setTime(res?.turn_on_time));
	};

	const handleConfirm = () => {
		dispatch.flux
			.processStream({ n_streams: selectLiveStream, organisationId: row.organisation[0].id })
			.then(() => setOpen(false));
	};

	return (
		<>
			<FormControl sx={{ m: 1, minWidth: 80 }} size='small'>
				<Select
					disabled={hasPermission(['user'])}
					displayEmpty
					inputProps={{ 'aria-label': 'Without label' }}
					value={selectLiveStream}
					onChange={handleChange}
					autoWidth>
					{row?.potentialStreamMaximum.map((value, index) => {
						return (
							<MenuItem key={index} value={value}>
								{value}
							</MenuItem>
						);
					})}
				</Select>
			</FormControl>

			<CustomDrawer
				open={open}
				setOpen={() => setOpen(!open)}
				title={t('flux.Changer le nombre de streams actifs')}
				text={t('flux.Confirmer votre sélection')}
				info={
					<Typography sx={{ fontSize: 12 }}>
						{t('flux.update1')}
						<Box component='span' sx={{ fontWeight: 700 }}>
							{t('flux.update2', { time })}
						</Box>
					</Typography>
				}>
				<Button
					onClick={handleConfirm}
					variant='contained'
					color='primary'
					sx={{ margin: '15px 0', width: '100%' }}>
					{t('common.Confirmer')}
				</Button>
			</CustomDrawer>
		</>
	);
};

export default SelectLiveStream;
