import { Box, Card, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material/';
import theme from '../../../styles/theme';

interface IDashboardCardProps {
	title: string;
	number: number;
	stat?: string;
	icon?: React.ReactNode;
	unit?: string;
	size?: string;
}
const DashboardCard: React.FC<IDashboardCardProps> = ({
	title,
	number,
	stat,
	icon,
	unit,
	size = '30%',
}): JSX.Element => {
	const { t, i18n } = useTranslation();
	return (
		<Card
			sx={{
				width: { xs: '90%', md: size },
				height: '120px',
				padding: '15px',
			}}>
			<Stack direction='row' alignItems='center' sx={{ marginBottom: '10px' }}>
				{icon}
				<Typography
					sx={{
						margin: '0px 10px',
						fontSize: 13,
						fontWeight: 600,
						alignContent: 'center',
						letterSpacing: '0.15px',
					}}
					color='text.secondary'
					gutterBottom>
					{t(`dashboard.${title}`)?.toUpperCase()}
				</Typography>
			</Stack>

			<Typography sx={{ fontSize: '40px', fontWeight: 700, marginBottom: 0 }} gutterBottom>
				{number}{' '}
				<span
					style={{
						color: '#BDBDBD',
						fontWeight: 400,
					}}>
					{unit &&
						`${unit}${unit !== '$' && number > 1 ? (i18n.language === 'de' ? 'n' : 's') : ''}`}
				</span>
			</Typography>

			{stat && (
				<Box
					style={{
						display: 'flex',
						alignItems: 'center',
						color: stat.includes('+')
							? theme.palette.success.main
							: theme.palette.error.main,
					}}>
					{stat.includes('-') ? (
						<ArrowDropDown color='error' sx={{ marginRight: '8px' }} />
					) : (
						<ArrowDropUp color='success' sx={{ marginRight: '8px' }} />
					)}
					{stat}
					<span
						style={{
							color: theme.palette.text.secondary,
							fontSize: '12px',
							marginLeft: '8px',
						}}>
						{t('dashboard.Depuis le mois dernier')}
					</span>
				</Box>
			)}
		</Card>
	);
};

export default DashboardCard;
