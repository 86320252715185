import * as React from 'react';
import CustomDrawer from '../../../../components/common/CustomDrawer';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import useGenerateField from '../../../../hooks/useGenerateField';
import useFields from '../constants/fields';
import { useSelectField } from '../../../../hooks/useSelectField';

interface ICreateUpdateOrganisationProps {
	open: boolean;
	setOpen: () => void;
	data: any;
}
const CreateUpdateInstance: React.FC<ICreateUpdateOrganisationProps> = ({
	open,
	setOpen,
	data,
}): JSX.Element => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { fields } = useFields();
	const generateFields = useGenerateField();
	const { selectValues, handleSelectChange, resetState } = useSelectField();

	const [error, setError] = React.useState(false);

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		const formData = new FormData(event.currentTarget);
		let hasEmptyField = false;

		const formValues: Record<string, string> = {};
		formData.forEach((value, key) => {
			if (!value) {
				hasEmptyField = true;
				setError(true);
			} else {
				formValues[key] = value as string;
			}
		});

		if (!hasEmptyField) {
			if (data) {
				dispatch.instances.update({ id: data.id, ...formValues });
			} else {
				dispatch.instances.create(formValues);
			}
			setOpen();
		}
	};

	const handleClose = () => {
		setOpen();
		resetState();
	};

	return (
		<CustomDrawer
			open={open}
			setOpen={handleClose}
			title={
				data
					? `${t('instances.Editer Instance')} ${data.name} `
					: t('instances.Créer une nouvelle instance')
			}>
			<form onSubmit={handleSubmit}>
				{fields.map((field: any) =>
					generateFields(
						field,
						data?.[field?.name],
						error,
						selectValues,
						handleSelectChange,
					),
				)}
				{error && (
					<p
						style={{
							color: '#d32e2f',
							textAlign: 'right',
							marginTop: 10,
							fontSize: 14,
						}}>
						{t('common.Tous les champs sont obligatoires.')}
					</p>
				)}
				<Button
					type='submit'
					variant='contained'
					color='primary'
					sx={{ margin: '15px 0', width: '100%' }}>
					{t('common.Sauvegarder')}
				</Button>
			</form>
		</CustomDrawer>
	);
};

export default CreateUpdateInstance;
