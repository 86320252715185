export const authPages = {
	login: {
		id: 'login',
		text: 'Login',
		path: '/auth-login',
	},
	forgetPaswword: {
		id: 'forgetPaswword',
		text: 'Forget Paswword',
		path: '/auth-login/forget-password',
	},
	page404: {
		id: 'Page404',
		text: '404 Page',
		path: '404',
	},
};

export const menu = {
	dashboard: {
		id: 'dashboard',
		text: 'Tableau de bord',
		path: '/',
		icon: 'dashboard',
	},
	flux: {
		id: 'gestion-flux',
		text: 'Gestion des flux',
		path: '/gestion-flux',
		icon: 'flux',
	},
	history: {
		id: 'historique',
		text: 'Historique',
		path: '/historique',
		icon: 'history',
	},
	parameters: {
		id: 'parametres',
		text: 'Paramètres',
		path: '/parametres',
		icon: 'parameters',
		hasPermissions: ['superadmin', 'admin'],
		subMenu: {
			organisations: {
				id: 'organisations',
				text: 'Liste des organisations',
				path: '/organisations',
				icon: 'organisations',
			},
			utilisateurs: {
				id: 'utilisateurs',
				text: 'Liste des utilisateurs',
				path: '/utilisateurs',
				icon: 'utilisateurs',
			},
			instances: {
				id: 'instances',
				text: 'Liste des instances',
				path: '/instances',
				icon: 'memories',
				hasPermissions: ['superadmin'],
			},
		},
	},
};

export const notificationsPages = {
	see: {
		id: 'seenotifications',
		text: 'Notifications',
		path: '/notifications',
		icon: '',
	},
	read: {
		id: 'readnotifications',
		text: 'Notification Details',
		path: '/notification-details',
		icon: '',
	},
};
