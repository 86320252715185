import { IHeadCellInstance } from '../../../../types/instances-types';

export const headCells: IHeadCellInstance[] = [
	{
		id: 'type',
		numeric: false,
		dateFormat: false,
		disablePadding: true,
		label: 'Type',
	},
	{
		id: 'instance_id',
		numeric: false,
		dateFormat: false,
		disablePadding: true,
		label: "Identifiant d'instance",
	},
	{
		id: 'name',
		numeric: false,
		dateFormat: false,
		disablePadding: false,
		label: 'Nom',
	},
	{
		id: 'autoshutdown_delay',
		numeric: false,
		dateFormat: false,
		disablePadding: false,
		label: "Délai d'extinction auto.",
		unit: 'secondes',
	},
	{
		id: 'dollar_per_hour',
		numeric: false,
		dateFormat: false,
		disablePadding: false,
		label: 'Coût / heure',
		unit: '$',
	},
	{
		id: 'fps',
		numeric: false,
		dateFormat: false,
		disablePadding: false,
		label: 'FPS',
	},
	{
		id: 'turning_on_delay',
		numeric: false,
		dateFormat: false,
		disablePadding: false,
		label: "Délai d'allumage",
	},
	{
		id: 'organisation',
		numeric: false,
		dateFormat: false,
		disablePadding: false,
		populate: 'name',
		label: 'Organisation',
	},
];
