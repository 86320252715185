import React from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import i18n from '../../../i18n';
import { useDispatch } from 'react-redux';
import FR from '../../../assets/img/FR.svg';
import EN from '../../../assets/img/UK.svg';
import DE from '../../../assets/img/DE.svg';
import IT from '../../../assets/img/IT.svg';
import ES from '../../../assets/img/SP.svg';

const LanguageSelector = () => {
	const dispatch = useDispatch();
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (event: any) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleLanguageChange = (lang: string) => {
		dispatch.auth.setLanguage(lang);
		i18n.changeLanguage(lang);
		handleClose();
	};

	const languages: { [key: string]: { label: string; icon: string } } = {
		fr: {
			label: 'Français',
			icon: FR, // Code Unicode pour le drapeau français
		},
		en: {
			label: 'English',
			icon: EN, // Code Unicode pour le drapeau anglais
		},
		es: {
			label: 'Español',
			icon: ES, // Code Unicode pour le drapeau espagnol
		},
		de: {
			label: 'Deutsch',
			icon: DE, // Code Unicode pour le drapeau allemand
		},
		it: {
			label: 'Italiano',
			icon: IT, // Code Unicode pour le drapeau italien
		},
	};

	return (
		<>
			<IconButton
				aria-controls='language-menu'
				aria-haspopup='true'
				onClick={handleClick}
				color='inherit'>
				<img
					src={languages[i18n.language.substring(0, 2)]?.icon}
					alt={i18n.language.substring(0, 2)}
					style={{ height: '25px', marginLeft: '17px' }}
				/>
			</IconButton>
			<Menu
				id='language-menu'
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}>
				{Object.keys(languages).map((lang) => {
					return (
						<MenuItem
							key={languages[lang].label}
							onClick={() => handleLanguageChange(lang)}
							selected={i18n.languages.includes(lang)}>
							<img
								style={{ marginRight: '10px' }}
								src={languages[lang].icon}
								alt={languages[lang].label}
							/>
							{` ${languages[lang].label}`}
						</MenuItem>
					);
				})}
			</Menu>
		</>
	);
};

export default LanguageSelector;
