import { PlayArrow, Stop } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { IVideo, IVideoStatusType } from '../../../types/flux-types';
import { FC } from 'react';
import { RootState } from '../../../store/store';

interface IVideoActionsProps {
	video: IVideo;
}

const VideoActions: FC<IVideoActionsProps> = ({ video }) => {
	const dispatch = useDispatch();
	const { flux } = useSelector((state: RootState) => state.flux);

	const handleStart = () => {
		dispatch.flux.play(video.id).then(() => {
			dispatch.flux.status(video.id).then((res: IVideoStatusType) => {
				video.video_status = res;
				const newFlux = { ...flux, video };
				dispatch.flux.set(newFlux, 'flex');
			});
		});
	};

	const handleStop = () => {
		dispatch.flux.stop(video.id).then(() => {
			dispatch.flux.status(video.id).then((res: IVideoStatusType) => {
				video.video_status = res;
				const newFlux = { ...flux, video };
				dispatch.flux.set(newFlux, 'flex');
			});
		});
	};

	if (video.video_status === 'NOT_PLAYING') {
		return (
			<IconButton
				onClick={(event) => {
					event.stopPropagation();
					handleStart();
				}}>
				<PlayArrow htmlColor='#636B74' sx={{ width: '20px', height: '20px' }} />
			</IconButton>
		);
	} else if (video.video_status === 'PLAYING') {
		return (
			<IconButton
				onClick={(event) => {
					event.stopPropagation();
					handleStop();
				}}>
				<Stop htmlColor='#636B74' sx={{ width: '20px', height: '20px' }} />
			</IconButton>
		);
	} else return null;
};

export default VideoActions;
