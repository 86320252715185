import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { Role } from '../types/auth-types';

export const usePermissions = () => {
	const { role } = useSelector((state: RootState) => state.auth);

	const hasPermission = (allowedRoles: Role[]) => {
		if (allowedRoles) {
			return allowedRoles?.includes(role);
		} else {
			return true;
		}
	};

	return { role, hasPermission };
};
