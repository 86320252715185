import { createModel } from '@rematch/core';
import { RootModel } from '..';
import { API_ENDPOINT, httpSecured } from '../../services/http';
import { IHistory } from '../../types/organisations-types';

export const history = createModel<RootModel>()({
	state: {
		history: [] as IHistory[],
	},
	reducers: {
		set: (state, payload, subject) => {
			return { ...state, [subject]: payload };
		},
	},
	effects: (dispatch) => ({
		async getAll(payload) {
			const { page, pageSize, date } = payload;
			try {
				const { data } = await httpSecured.get(
					`${API_ENDPOINT.HISTORY.GET_HISTORY}?page=${page}&pageSize=${pageSize}${date ? `&date=${date}` : ''}`,
				);
				if (data) {
					dispatch.history.set(data.history, 'history');
					return data;
				}
			} catch (error: any) {
				console.error(error);
				if (error.response?.status === 401) {
					dispatch.auth.logout();
				}
			}
		},

		async delete(payload) {
			const { id, page, pageSize, date } = payload;
			try {
				await httpSecured.delete(`${API_ENDPOINT.HISTORY.DELETE_HISTORY}${id}`);

				dispatch.alerts.setAlert({
					open: true,
					severity: 'success',
					message: "L'historique a été supprimé avec succès.",
				});
				if (pageSize) this.getAll({ page, pageSize, date });
				return true;
			} catch (error: any) {
				console.error(error);
				if (error.response?.status === 401) {
					dispatch.auth.logout();
				}
				dispatch.alerts.setAlert({
					open: true,
					severity: 'error',
					message:
						typeof error.response.data.error == 'string'
							? error.response.data.error
							: error.response.data.message ||
								"Une erreur est survenue lors de la suppression de l'historique.",
				});
				return false;
			}
		},
	}),
});
