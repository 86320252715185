import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import i18n from '../i18n';
import { frFR, esES, enUS, deDE, itIT } from '@mui/x-date-pickers/locales';

declare module '@mui/material/Button' {
	interface ButtonPropsVariantOverrides {
		custom: true;
	}
}

export const chooseLanguage = () => {
	switch (i18n.language) {
		case 'fr':
			return frFR;
		case 'it':
			return itIT;
		case 'es':
			return esES;
		case 'de':
			return deDE;
		default:
			return enUS;
	}
};

const breakpoints = {
	values: {
		xs: 0,
		sm: 600, // Phone
		md: 900, // Tablet/Laptop
		lg: 1200, // Desktop
		xl: 1576,
	},
};

let theme = createTheme(
	{
		palette: {
			primary: {
				main: '#F18A65',
				dark: '#F6B8A2',
				contrastText: '#FBFCFE',
			},
			secondary: {
				main: 'rgba(0, 0, 0, 0.12)',
				dark: '#253237',
				contrastText: 'rgba(37, 50, 55, 1)',
			},
			error: {
				main: '#D32F2F',
			},
			success: {
				main: '#2E7D32',
				dark: '#69F0AE',
				contrastText: '#FFFFFF',
			},
			text: {
				primary: '#253237',
				secondary: '#6B7280',
			},
		},
		breakpoints,
		components: {
			MuiButton: {
				defaultProps: {
					variant: 'custom',
				},
				variants: [
					{
						props: { variant: 'custom' },
						style: {
							height: '40px',
							borderRadius: '5px',
							color: '#253237',
							border: '1px solid rgba(0, 0, 0, 0.12)',
							'&:hover': {
								color: '#253237',
							},
						},
					},
				],
				styleOverrides: {
					root: ({ ownerState }) => ({
						...(ownerState.variant === 'outlined' && {
							color: '#253237',
							height: '40px',
							border: '1px solid #E1E8EA',
							'&:hover': {
								background: '#F6F8F9',
								border: '1px solid #F6F8F9',
							},
						}),

						borderRadius: '5px',
						textTransform: 'none',
					}),
				},
			},
			MuiTooltip: {
				styleOverrides: {
					tooltip: {
						backgroundColor: '#E3EFFB',
						color: '#12467B',
						boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
						fontSize: '14px',
					},
					arrow: { color: '#E3EFFB' },
				},
			},
			MuiBadge: {
				styleOverrides: {
					colorSuccess: {
						backgroundColor: '#66BB6A',
					},
				},
			},
		},
		typography: {
			h1: {
				fontFamily: ['Inter', 'cursive'].join(','),
				fontSize: '2.375rem',
				fontWeight: 700,
				marginRight: 2,
				[`@media screen and (max-width: ${breakpoints.values.sm}px)`]: {
					fontWeight: 500,
				},
			},
			h2: {
				fontFamily: ['Inter', 'cursive'].join(','),
				fontSize: '1.5rem',
				fontWeight: 700,
				marginRight: 2,
			},
			h3: {
				fontSize: '1.3rem',
				fontWeight: 400,
				marginRight: 2,
			},
			h4: {
				fontSize: '16px',
				fontWeight: 700,
				marginBottom: '20px',
			},
		},
	},
	chooseLanguage(),
);

theme = responsiveFontSizes(theme);

export default theme;
