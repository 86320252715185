import { createModel } from '@rematch/core';
import { RootModel } from '..';
import { API_ENDPOINT, httpSecured, httpSecuredWithoutApi } from '../../services/http';
import { IOneVideo, IVideo, ILiveStream } from '../../types/flux-types';

export const flux = createModel<RootModel>()({
	state: {
		flux: [] as IVideo[],
		video: {} as IOneVideo,
		active_stream: [] as ILiveStream[],
	},
	reducers: {
		set: (state, payload, subject) => {
			return { ...state, [subject]: payload };
		},
	},
	effects: (dispatch) => ({
		async getAll() {
			try {
				const { data } = await httpSecured.get(API_ENDPOINT.FLUX.GET_FLUX);
				if (data) {
					dispatch.flux.set(data.videos, 'flux');
					this.getSupervisorStatus();
					return data;
				}
			} catch (error: any) {
				console.error(error);
				if (error.response?.status === 401) {
					dispatch.auth.logout();
				}
			}
		},
		async getOne(payload) {
			const { id, page, pageSize } = payload;
			try {
				const { data } = await httpSecured.get(
					`${API_ENDPOINT.FLUX.GET_ONE_FLUX}${id}/?page=${page}&pageSize=${pageSize}`,
				);
				if (data) {
					dispatch.flux.set(data, 'video');
					return data;
				}
			} catch (error: any) {
				console.error(error);
				if (error.response?.status === 401) {
					dispatch.auth.logout();
				}
			}
		},
		async create(payload) {
			try {
				const { data } = await httpSecured.post(API_ENDPOINT.FLUX.CREATE_FLUX, payload);
				if (data) {
					dispatch.alerts.setAlert({
						open: true,
						severity: 'success',
						message: 'La vidéo a été ajoutée avec succès.',
					});
					this.getAll();
					return true;
				}
			} catch (error: any) {
				console.error(error);
				if (error.response?.status === 401) {
					dispatch.auth.logout();
				}
				dispatch.alerts.setAlert({
					open: true,
					severity: 'error',
					message:
						typeof error.response.data.error == 'string'
							? error.response.data.error
							: 'Une erreur est survenue lors de la création de la vidéo.',
				});
				return false;
			}
		},
		async update(payload) {
			try {
				const { data } = await httpSecured.put(
					`${API_ENDPOINT.FLUX.UPDATE_FLUX}${payload.id}/`,
					payload.data as FormData,
				);
				if (data) {
					dispatch.alerts.setAlert({
						open: true,
						severity: 'success',
						message: 'La vidéo a été modifiée avec succès.',
					});
					this.getAll();
					return true;
				}
			} catch (error: any) {
				console.error(error);
				if (error.response?.status === 401) {
					dispatch.auth.logout();
				}
				dispatch.alerts.setAlert({
					open: true,
					severity: 'error',
					message:
						typeof error.response.data.error == 'string'
							? error.response.data.error
							: error.response.data.message ||
								'Une erreur est survenue lors de la modification de la vidéo.',
				});
			}
		},
		async delete(id) {
			try {
				await httpSecured.delete(`${API_ENDPOINT.FLUX.DELETE_FLUX}${id}`);

				dispatch.alerts.setAlert({
					open: true,
					severity: 'success',
					message: 'La vidéo a été supprimée avec succès.',
				});
				this.getAll();
				return true;
			} catch (error: any) {
				console.error(error);
				if (error.response?.status === 401) {
					dispatch.auth.logout();
				}
				dispatch.alerts.setAlert({
					open: true,
					severity: 'error',
					message:
						typeof error.response.data.error == 'string'
							? error.response.data.error
							: error.response.data.message ||
								'Une erreur est survenue lors de la suppression de la vidéo.',
				});
				return false;
			}
		},
		async deleteHistory(payload) {
			const { id, videoId } = payload;
			try {
				await httpSecured.delete(`${API_ENDPOINT.HISTORY.DELETE_HISTORY}${id}`);

				dispatch.alerts.setAlert({
					open: true,
					severity: 'success',
					message: "L'historique de la vidéo a été supprimée avec succès.",
				});
				this.getOne(videoId);
				return true;
			} catch (error: any) {
				console.error(error);
				if (error.response?.status === 401) {
					dispatch.auth.logout();
				}
				dispatch.alerts.setAlert({
					open: true,
					severity: 'error',
					message:
						typeof error.response.data.error == 'string'
							? error.response.data.error
							: error.response.data.message ||
								"Une erreur est survenue lors de la suppression de l'historique de la vidéo.",
				});
				return false;
			}
		},
		async play(id) {
			try {
				const { status } = await httpSecured.get(`${API_ENDPOINT.FLUX.PLAY}${id}`);
				if (status === 200) return true;
			} catch (error: any) {
				console.error(error);
				if (error.response?.status === 401) {
					dispatch.auth.logout();
				}
				return false;
			}
		},
		async stop(id) {
			try {
				const { status } = await httpSecured.get(`${API_ENDPOINT.FLUX.STOP}${id}`);
				if (status === 200) {
					this.getAll();
					return true;
				}
			} catch (error: any) {
				console.error(error);
				if (error.response?.status === 401) {
					dispatch.auth.logout();
				}
				return false;
			}
		},
		async pause(id) {
			try {
				const { status } = await httpSecured.get(`${API_ENDPOINT.FLUX.PAUSE}${id}`);
				if (status === 200) return true;
			} catch (error: any) {
				console.error(error);
				if (error.response?.status === 401) {
					dispatch.auth.logout();
				}
				return false;
			}
		},
		async status(id) {
			try {
				const { status, data } = await httpSecured.get(`${API_ENDPOINT.FLUX.STATUS}${id}`);
				if (status === 200) return data.video_status;
			} catch (error: any) {
				console.error(error);
				if (error.response?.status === 401) {
					dispatch.auth.logout();
				}
			}
		},
		async exportCSV(id) {
			try {
				const { data } = await httpSecured.get(`${API_ENDPOINT.FLUX.EXPORT_CSV}${id}`);
				if (data) {
					return data;
				}
			} catch (error: any) {
				console.error(error);
				if (error.response?.status === 401) {
					dispatch.auth.logout();
				}
			}
		},
		async startSupervisor(payload) {
			try {
				const { data } = await httpSecuredWithoutApi.post(
					API_ENDPOINT.FLUX.SUPERVISOR_START,
					payload,
				);
				if (data) {
					return true;
				}
			} catch (error: any) {
				console.error(error);
				if (error.response?.status === 401) {
					dispatch.auth.logout();
					return false;
				}
				dispatch.alerts.setAlert({
					open: true,
					severity: 'error',
					message:
						typeof error.response.data.error == 'string'
							? error.response.data.error
							: error.response.data.message ||
								"Une erreur est survenue lors de l'allumage du superviseur",
				});
			}
		},
		async stopSupervisor(payload) {
			try {
				const { data } = await httpSecuredWithoutApi.post(
					API_ENDPOINT.FLUX.SUPERVISOR_STOP,
					payload,
				);
				if (data) {
					return data;
				}
			} catch (error: any) {
				console.error(error);
				if (error.response?.status === 401) {
					dispatch.auth.logout();
				}
				dispatch.alerts.setAlert({
					open: true,
					severity: 'error',
					message:
						typeof error.response.data.error == 'string'
							? error.response.data.error
							: error.response.data.message ||
								"Une erreur est survenue lors de l'arrêt du superviseur",
				});
			}
		},
		async getSupervisorStatus(_: void, state) {
			try {
				const { status, data } = await httpSecuredWithoutApi.get(
					`${API_ENDPOINT.FLUX.SUPERVISOR_STATUS}`,
				);
				if (status === 200) {
					if (data.isRunning) {
						const newState = state.flux.flux;
						newState.forEach((video: IVideo) => {
							if (data.isRunning.includes(video.id)) {
								video.notification = true;
							}
							return this.set(newState, 'flux');
						});
					}
				}
			} catch (error: any) {
				console.error(error);
				if (error.response?.status === 401) {
					dispatch.auth.logout();
				}
			}
		},
		async getLiveStream() {
			try {
				const { data } = await httpSecured.get(API_ENDPOINT.ORGANISATION.ACTIVE_STREAM);

				if (data) {
					const updatedData = data.map((item: any, index: number) => ({
						...item,
						id: index + 1,
					}));

					this.set(updatedData, 'active_stream');
					return data;
				}
			} catch (error: any) {
				console.error(error);
				if (error.response?.status === 401) {
					dispatch.auth.logout();
				}
			}
		},
		async processStream(payload) {
			try {
				const { data } = await httpSecured.post(
					`${API_ENDPOINT.ORGANISATION.PROCESS_STREAM}${payload.organisationId}/`,
					payload.only_estimation
						? { n_streams: payload.n_streams, only_estimation: payload.only_estimation }
						: { n_streams: payload.n_streams },
				);

				if (data) {
					if (!payload.only_estimation) {
						dispatch.alerts.setAlert({
							open: true,
							severity: 'success',
							message: 'Le nombre maximum de streams actifs a été mis à jour.',
						});
					}
					return data;
				}
			} catch (error: any) {
				console.error(error);
				if (error.response?.status === 401) {
					dispatch.auth.logout();
				}
				dispatch.alerts.setAlert({
					open: true,
					severity: 'error',
					message:
						typeof error.response.data.error == 'string'
							? error.response.data.error
							: error.response.data.message ||
								'Une erreur est survenue lors de la mise à jour du nombre de streams actifs.',
				});
			}
		},
	}),
});
