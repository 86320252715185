import * as React from 'react';
import {
	Box,
	Collapse,
	Divider,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Toolbar,
	Tooltip,
	useMediaQuery,
} from '@mui/material';
import {
	SettingsOutlined,
	ExpandLess,
	ExpandMore,
	RestoreOutlined,
	ApartmentOutlined,
	PlayCircleOutlined,
	GroupOutlined,
	MenuOpenOutlined,
	SpaceDashboardOutlined,
	MemoryOutlined,
} from '@mui/icons-material';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import { menu } from '../../../routes/menu';
import { useLocation, useNavigate } from 'react-router-dom';
import theme from '../../../styles/theme';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { usePermissions } from '../../../hooks/usePermissions';
import { Role } from '../../../types/auth-types';

const drawerWidth = 240;

const Aside = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const { hasPermission } = usePermissions();

	const [collapse, setCollapse] = React.useState(true);
	const [selectedItem, setSelectedItem] = React.useState(
		pathname === '/' ? 'dashboard' : pathname.substring(1),
	);

	const { open } = useSelector((state: RootState) => state.auth);

	const isMobile = useMediaQuery(theme.breakpoints.down('md'));

	const handleListItemClick = (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
		index: string,
	) => {
		setSelectedItem(index);
	};

	const displayIcon = (icon: string) => {
		switch (icon) {
			case 'dashboard':
				return <SpaceDashboardOutlined htmlColor='#f5ad93' />;
			case 'flux':
				return <PlayCircleOutlined htmlColor='#f5ad93' />;
			case 'history':
				return <RestoreOutlined htmlColor='#f5ad93' />;
			case 'parameters':
				return <SettingsOutlined htmlColor='#f5ad93' />;
			case 'organisations':
				return <ApartmentOutlined htmlColor='#f5ad93' />;
			case 'utilisateurs':
				return <GroupOutlined htmlColor='#f5ad93' />;
			case 'memories':
				return <MemoryOutlined htmlColor='#f5ad93' />;
			default:
				break;
		}
	};

	function fillMenu(
		data:
			| {
					id?: string | number;
					text?: string;
					path?: string;
					icon?: string;
					hasPermissions?: Role[];
					subMenu?:
						| {
								id?: string | number;
								text?: string;
								path?: string;
								icon?: string;
						  }[]
						| undefined;
			  }[]
			| any,
		submenuItem?: boolean,
	) {
		return Object.keys(data).map((item) => {
			const canSeeMenuItem = hasPermission(data[item]?.hasPermissions);
			return (
				canSeeMenuItem && (
					<React.Fragment key={data[item].id}>
						<ListItem id={data[item].id} sx={{ display: 'block', padding: '5px 8px' }}>
							<ListItemButton
								sx={{
									minHeight: 48,
									justifyContent: open ? 'initial' : 'center',
									px: 2.5,
									borderRadius: '4px',
									'&.MuiListItemButton-root:hover': {
										backgroundColor: '#FFFFFF14',
									},
								}}
								selected={selectedItem === data[item].id}
								onClick={(event) => {
									if (isMobile && !data[item].subMenu)
										dispatch.auth.setOpen(!open);
									handleListItemClick(event, data[item].id);
									data[item].subMenu
										? setCollapse(!collapse)
										: navigate(`../${data[item].path}`);
								}}>
								{!open ? (
									<Tooltip
										title={t(`menu.${data[item].text}`)}
										placement='right'
										arrow
										color='#12467B'
										PopperProps={{
											modifiers: [
												{
													name: 'offset',
													options: {
														offset: [0, 15],
													},
												},
											],
										}}>
										<ListItemIcon
											sx={{
												minWidth: 0,
												mr: open ? 3 : 'auto',
												justifyContent: 'center',
											}}>
											{displayIcon(data[item].icon)}
										</ListItemIcon>
									</Tooltip>
								) : (
									<ListItemIcon
										sx={{
											minWidth: 0,
											mr: open ? 3 : 'auto',
											justifyContent: 'center',
										}}>
										{displayIcon(data[item].icon)}
									</ListItemIcon>
								)}
								<ListItemText
									sx={{ display: open ? 'block' : 'none' }}
									primary={t(`menu.${data[item].text}`)}
									primaryTypographyProps={{
										fontSize: submenuItem ? '12px' : '16px',
									}}
								/>

								{data[item].subMenu && open ? (
									collapse ? (
										<ExpandLess color='primary' />
									) : (
										<ExpandMore />
									)
								) : null}
								{/* 	{!!data[item].subMenu && fillMenu(data[item].subMenu)} */}
							</ListItemButton>
						</ListItem>
						{data[item].subMenu && collapse && open ? (
							<Divider sx={{ margin: '0px 10px', backgroundColor: '#FFFFFF80' }} />
						) : null}
						{!!data[item].subMenu && (
							<Collapse in={collapse} timeout='auto' unmountOnExit>
								{!!data[item].subMenu && fillMenu(data[item].subMenu, true)}
							</Collapse>
						)}
					</React.Fragment>
				)
			);
		});
	}

	const openedMixin = (theme: Theme): CSSObject => ({
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		overflowX: 'hidden',
	});

	const closedMixin = (theme: Theme): CSSObject => ({
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: 'hidden',
		width: isMobile ? 0 : `calc(${theme.spacing(7)} + 1px)`,
		[theme.breakpoints.up('sm')]: {
			width: isMobile ? 0 : `calc(${theme.spacing(8)} + 1px)`,
		},
	});

	const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
		({ theme, open }) => ({
			width: drawerWidth,
			flexShrink: 0,
			whiteSpace: 'nowrap',
			boxSizing: 'border-box',
			...(open && {
				...openedMixin(theme),
				'& .MuiDrawer-paper': openedMixin(theme),
			}),
			...(!open && {
				...closedMixin(theme),
				'& .MuiDrawer-paper': closedMixin(theme),
			}),
		}),
	);

	React.useEffect(() => {
		if (isMobile) {
			dispatch.auth.setOpen(false);
		} else {
			dispatch.auth.setOpen(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isMobile]);

	return (
		<Drawer
			id='drawer'
			open={open}
			variant='permanent'
			sx={{
				[`& .MuiDrawer-paper`]: {
					backgroundColor: theme.palette.text.primary,
				},
			}}
			ModalProps={{
				keepMounted: true,
			}}>
			<Toolbar />

			<Box sx={{ overflow: 'auto', marginTop: 5 }}>
				<List sx={{ color: '#fff' }}>
					{!isMobile && (
						<ListItem sx={{ display: 'block', padding: '5px 8px' }}>
							<ListItemButton
								sx={{
									minHeight: 48,
									justifyContent: open ? 'initial' : 'center',
									px: 2.5,
									borderRadius: '4px',
									'&.MuiListItemButton-root:hover': {
										backgroundColor: '#FFFFFF14',
									},
								}}
								onClick={() => dispatch.auth.setOpen(!open)}>
								<ListItemIcon
									sx={{
										minWidth: 0,
										mr: open ? 3 : 'auto',
										justifyContent: 'center',
									}}>
									{open ? (
										<MenuOpenOutlined sx={{ color: '#fff' }} />
									) : (
										<MenuOpenOutlined
											sx={{ color: '#fff', transform: 'rotate(180deg)' }}
										/>
									)}
								</ListItemIcon>
							</ListItemButton>
						</ListItem>
					)}
					{fillMenu(menu)}
				</List>
			</Box>
		</Drawer>
	);
};

export default Aside;
