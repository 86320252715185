import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import React from 'react';

const useFields = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { organisations } = useSelector((state: RootState) => state.organisations);
	const { token } = useSelector((state: RootState) => state.auth);

	React.useEffect(() => {
		if (token) dispatch.organisations.getAll();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [token]);

	const instanceType = ['ec2'];

	const fields = [
		{
			type: 'select',
			name: 'type',
			label: t('instances.Type'),
			placeholder: 'Ex: EC2',
			multiple: false,
			options: instanceType.map((name) => {
				return { label: name?.toUpperCase(), value: name };
			}),
		},
		{
			type: 'text',
			name: 'instance_id',
			label: t("instances.Identifiant d'instance"),
			placeholder: 'Ex: id - xyz',
		},
		{
			type: 'text',
			name: 'name',
			label: t('instances.Nom'),
			placeholder: 'Ex: Test',
		},
		{
			type: 'text',
			name: 'autoshutdown_delay',
			label: t("instances.Délai d'extinction auto."),
			unit: t('instances.secondes'),
			placeholder: 'Ex: 3600',
		},
		{
			type: 'text',
			name: 'dollar_per_hour',
			label: t('instances.Coût / heure'),
			unit: '$',
			placeholder: 'Ex: 0.28',
		},
		{
			type: 'text',
			name: 'fps',
			label: t('instances.FPS'),
			placeholder: 'Ex: 25',
		},
		{
			type: 'text',
			name: 'turning_on_delay',
			label: t("instances.Délai d'allumage"),
			unit: t('instances.secondes'),
			placeholder: 'Ex: 300',
		},
		{
			type: 'select',
			name: 'organisation',
			label: t('instances.Organisation'),
			multiple: false,
			placeholder: t('users.Choissisez une organisation'),
			options: organisations.map((organisation) => {
				return { label: organisation.name, value: organisation.id };
			}),
			style: { marginBottom: 0 },
		},
	];

	return { fields };
};

export default useFields;
