import { IHeadCellUser } from '../../../../types/user-types';

export const headCells: IHeadCellUser[] = [
	{
		id: ['last_name', 'first_name'],
		numeric: false,
		dateFormat: false,
		disablePadding: true,
		label: 'Utilisateurs',
	},
	{
		id: 'email',
		numeric: false,
		dateFormat: false,
		disablePadding: false,
		label: 'Email',
	},
	{
		id: 'role',
		numeric: false,
		dateFormat: false,
		disablePadding: false,
		label: 'Role',
	},
	{
		id: 'organisations',
		numeric: false,
		dateFormat: false,
		disablePadding: false,
		populate: 'name',
		label: 'Organisation(s)',
	},
	{
		id: 'created_at',
		numeric: false,
		dateFormat: true,
		disablePadding: false,
		label: "Date d'ajout",
	},
];
