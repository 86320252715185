import React from 'react';
import { authPages, menu, notificationsPages } from './menu';
import Page404 from '../pages/common/Page404';
import Login from '../pages/authentification/Login';
import Dashboard from '../pages/dashboard/Dashboard';
import GestionFlux from '../pages/gestionFlux/GestionFlux';
import OrganisationsList from '../pages/parameters/organisations/Organisations';
import OrganisationDetails from '../pages/parameters/organisations/components/OrganisationDetails';
import UsersList from '../pages/parameters/users/Users';
import HistoryList from '../pages/history/History';
import FluxDetails from '../pages/gestionFlux/components/FluxDetails';
import Notifications from '../pages/notifications/Notifications';
import DetectionDetails from '../pages/notifications/components/Detection';
import ForgetPassword from '../pages/authentification/ForgetPassword';
import InstancesList from '../pages/parameters/instances/Instances';

const authentification = [
	{
		path: authPages.page404.path,
		element: <Page404 />,
		exact: true,
	},
	{
		path: authPages.login.path,
		element: <Login />,
		exact: true,
	},
	{
		path: authPages.forgetPaswword.path,
		element: <ForgetPassword />,
		exact: true,
	},
];

const aside = [
	{
		path: menu.dashboard.path,
		element: <Dashboard />,
		exact: true,
	},
	{
		path: menu.flux.path,
		element: <GestionFlux />,
		exact: true,
	},
	{
		path: `${menu.flux.path}/:id`,
		element: <FluxDetails />,
		exact: true,
	},
	{
		path: menu.history.path,
		element: <HistoryList />,
		exact: true,
	},
	{
		path: menu.parameters.subMenu.organisations.path,
		element: <OrganisationsList />,
		exact: true,
	},
	{
		path: `${menu.parameters.subMenu.organisations.path}/:id`,
		element: <OrganisationDetails />,
		exact: true,
	},
	{
		path: menu.parameters.subMenu.utilisateurs.path,
		element: <UsersList />,
		exact: true,
	},
	{
		path: menu.parameters.subMenu.instances.path,
		element: <InstancesList />,
		exact: true,
	},
	{
		path: notificationsPages.see.path,
		element: <Notifications />,
		exact: true,
	},
	{
		path: `${notificationsPages.read.path}/:id`,
		element: <DetectionDetails />,
		exact: true,
	},
];

const contents = [...authentification, ...aside];

export default contents;
