import { Icon, IconButton, Switch } from '@mui/material';
import React, { FC, useEffect } from 'react';
import { IVideo } from '../../../types/flux-types';
import { useDispatch } from 'react-redux';

interface ISupervisorSwitchProps {
	video: IVideo;
}

const SupervisorSwitch: FC<ISupervisorSwitchProps> = ({ video }) => {
	const dispatch = useDispatch();
	const [checked, setChecked] = React.useState(video.notification ?? false);

	useEffect(() => {
		if (video.notification) {
			setChecked(video.notification);
		}
	}, [video.notification]);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { camera_type, id } = video;
		setChecked(event.target.checked);
		if (event.target.checked) {
			dispatch.flux.startSupervisor({ camera_type, video_id: id }).then((res: boolean) => {
				if (!res) setChecked(false);
			});
		} else {
			dispatch.flux.stopSupervisor({ camera_type, video_id: id }).then((res: boolean) => {
				if (!res) setChecked(false);
			});
		}
	};

	return (
		<IconButton
			onClick={(e) => e.stopPropagation()}
			sx={{ ':hover': { backgroundColor: 'transparent' } }}>
			<Switch
				checked={checked}
				onChange={handleChange}
				inputProps={{ 'aria-label': 'controlled' }}
			/>
		</IconButton>
	);
};

export default SupervisorSwitch;
