import { IHeadCellFlux, IHeadCellLiveStreams } from '../../../types/flux-types';

export const headCells: IHeadCellFlux[] = [
	{
		id: 'name',
		numeric: false,
		dateFormat: false,
		disablePadding: true,
		label: 'Nom du flux',
	},
	{
		id: 'detection_type',
		numeric: false,
		dateFormat: false,
		disablePadding: false,
		label: 'Dernière détection',
	},
	{
		id: 'video_status',
		numeric: false,
		dateFormat: false,
		disablePadding: false,
		label: 'Santé du flux',
	},
	{
		id: 'play_stop',
		numeric: false,
		dateFormat: false,
		disablePadding: false,
		label: 'Play / Stop',
	},
	{
		id: 'detection_date',
		numeric: true,
		dateFormat: true,
		disablePadding: false,
		label: 'Date de détection',
	},
	{
		id: 'localisation',
		numeric: false,
		dateFormat: false,
		disablePadding: false,
		label: 'Localisation',
	},
	{
		id: 'source',
		numeric: false,
		dateFormat: false,
		disablePadding: false,
		label: 'Type de source',
	},
	{
		id: 'camera_type',
		numeric: false,
		dateFormat: false,
		disablePadding: false,
		label: 'Type de caméra',
	},
	{
		id: 'notification',
		numeric: false,
		dateFormat: false,
		disablePadding: false,
		label: 'Notification',
	},
];

export const headCellsLiveStream: IHeadCellLiveStreams[] = [
	{
		id: 'organisation',
		numeric: true,
		dateFormat: false,
		disablePadding: false,
		populate:'name',
		label: 'Organisation',
	},
	{
		id: 'currentlyRunnningStreams',
		numeric: true,
		dateFormat: false,
		disablePadding: false,
		label: 'Flux actifs',
	},
	{
		id: 'currentStreamMaximum',
		numeric: true,
		dateFormat: false,
		disablePadding: false,
		label: 'Nbre de flux actifs maximum',
	},
];
