import React from 'react';
import { IconButton, Stack } from '@mui/material';
import {
	DeleteOutlineOutlined,
	EditOutlined,
	InboxOutlined,
	PersonAddAlt,
	RemoveRedEyeOutlined,
} from '@mui/icons-material';

interface CrudCellProps {
	onAdd?: (e: number) => void;
	onSee?: (e: number) => void;
	onEdit?: (e: number) => void;
	onDelete?: (e: number) => void;
	onArchive?: (e: number) => void;
	rowId: number;
}

const CrudCell: React.FC<CrudCellProps> = ({
	onAdd,
	onSee,
	onEdit,
	onDelete,
	onArchive,
	rowId,
}) => {
	return (
		<Stack direction='row' spacing={0.5}>
			{onSee && (
				<IconButton aria-label='see' onClick={() => onSee(rowId)}>
					<RemoveRedEyeOutlined sx={{ width: '20px' }} />
				</IconButton>
			)}
			{onAdd && (
				<IconButton aria-label='add' onClick={() => onAdd(rowId)}>
					<PersonAddAlt sx={{ width: '20px' }} />
				</IconButton>
			)}
			{onEdit && (
				<IconButton aria-label='edit' onClick={() => onEdit(rowId)}>
					<EditOutlined sx={{ width: '20px' }} />
				</IconButton>
			)}
			{onDelete && (
				<IconButton aria-label='delete' onClick={() => onDelete(rowId)}>
					<DeleteOutlineOutlined sx={{ width: '20px' }} />
				</IconButton>
			)}
			{onArchive && (
				<IconButton aria-label='archive' onClick={() => null}>
					<InboxOutlined sx={{ width: '20px' }} />
				</IconButton>
			)}
		</Stack>
	);
};

export default CrudCell;
