import * as React from 'react';
import {
	Button,
	FormControl,
	FormControlLabel,
	FormLabel,
	Radio,
	RadioGroup,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import useGenerateField from '../../../hooks/useGenerateField';
import useFields from '../constants/fields';
import CustomDrawer from '../../../components/common/CustomDrawer';
import { useSelectField } from '../../../hooks/useSelectField';

interface ICreateUpdateFluxProps {
	open: boolean;
	setOpen: () => void;
	data: any;
}
const CreateUpdateFlux: React.FC<ICreateUpdateFluxProps> = ({
	open,
	setOpen,
	data,
}): JSX.Element => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { fields } = useFields();
	const generateFields = useGenerateField();
	const { selectValues, handleSelectChange, resetState } = useSelectField();

	const [error, setError] = React.useState(false);
	const [selectedRadioItem, setSelectedRadioItem] = React.useState(data?.source ?? '');
	const [selectedFile, setSelectedFile] = React.useState<File | null>(null);

	React.useEffect(() => {
		if (data) setSelectedRadioItem(data?.source);
	}, [data]);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.value === 'local') {
			document.getElementById('raised-button-file-local')?.click();
		}
		setSelectedRadioItem((event.target as HTMLInputElement).value);
		setSelectedFile(null);
	};

	const handleSelectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.files && event.target.files[0]) {
			setSelectedFile(event.target.files[0]);
		}
	};

	const handleClose = () => {
		setOpen();
		resetState();
		setSelectedFile(null);
		setError(false);
	};

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		const formData = new FormData(event.currentTarget);
		let hasEmptyField = false;

		if (selectedFile) formData.append('video', selectedFile);
		if (data?.source === 'local') formData.append('url', data?.url);

		// Ajouter les valeurs des champs dynamiques
		Object.keys(selectValues).forEach((key) => {
			formData.append(key, selectValues[key] as any);
		});

		formData.forEach((value) => {
			if (!value) {
				hasEmptyField = true;
				setError(true);
			}
		});

		if (!hasEmptyField) {
			if (data) {
				dispatch.flux.update({ id: data.id, data: formData as FormData });
			} else {
				dispatch.flux.create(formData as FormData);
			}
			handleClose();
		}
	};

	return (
		<CustomDrawer
			open={open}
			setOpen={handleClose}
			title={data ? t('flux.Editer un flux') : t('flux.Créer un nouveau flux')}>
			<form onSubmit={handleSubmit} encType='multipart/form-data'>
				{fields.map((field: any) => {
					var errors = false;
					if (field?.name !== 'serial_number') {
						errors = error;
					}
					if (
						field.dependantField &&
						!(selectValues && Object.keys(selectValues).includes(field.dependantField)
							? (selectValues?.[field.dependantField] as unknown as boolean)
							: data?.[field?.name]
								? data?.[field?.name]
								: false)
					) {
						return null;
					} else {
						return generateFields(
							field,
							data?.[field?.name],
							errors,
							selectValues,
							handleSelectChange,
						);
					}
				})}
				<FormControl sx={{ width: '100%' }}>
					<FormLabel id='demo-controlled-radio-buttons-group' required>
						{t('flux.Choix de la source')}
					</FormLabel>
					<RadioGroup
						row
						aria-labelledby='demo-controlled-radio-buttons-group'
						name='source'
						value={selectedRadioItem}
						onChange={handleChange}>
						<label
							htmlFor='raised-button-file-local'
							style={{ cursor: 'pointer', marginRight: '16px' }}>
							<input
								accept='video/*'
								style={{
									display: 'none',
								}}
								id='raised-button-file-local'
								type='file'
								onClick={() => setSelectedRadioItem('local')}
								onChange={handleSelectFile}
							/>
							<Radio value='local' checked={selectedRadioItem === 'local'} required />
							{t('flux.Local')}
						</label>

						<FormControlLabel value='url' control={<Radio />} label='URL' />
						<FormControlLabel
							value='offline camera'
							control={<Radio />}
							label='Offline cam.'
						/>
					</RadioGroup>
				</FormControl>
				{(selectedFile || data?.source === 'local') && (
					<Stack direction='row' justifyContent='space-between'>
						<Typography variant='caption'>
							Fichier sélectionné :{' '}
							{selectedFile?.name || (data?.source === 'local' && data?.url)}
						</Typography>
						<Typography
							variant='caption'
							sx={{ cursor: 'pointer' }}
							onClick={() => setSelectedFile(null)}>
							x
						</Typography>
					</Stack>
				)}
				{selectedRadioItem === ('url' || 'local') && (
					<TextField
						type={selectedRadioItem === 'local' ? 'file' : 'text'}
						id={`outlined-basic-${'name'}`}
						variant='outlined'
						name='url'
						required
						label='URL'
						placeholder='https://www....'
						defaultValue={data?.url}
						error={error}
						style={{ margin: '20px 0px', width: '100%' }}
					/>
				)}
				{error && (
					<p
						style={{
							color: '#d32e2f',
							textAlign: 'right',
							marginTop: 10,
							fontSize: 14,
						}}>
						*{t('common.Tous les champs sont obligatoires.')}
					</p>
				)}
				<Button
					type='submit'
					variant='contained'
					color='primary'
					sx={{ margin: '15px 0', width: '100%' }}>
					{t('common.Sauvegarder')}
				</Button>
			</form>
		</CustomDrawer>
	);
};

export default CreateUpdateFlux;
