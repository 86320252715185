import React from 'react';
import {
	TextField,
	Select,
	MenuItem,
	FormControl,
	InputLabel,
	TextareaAutosize,
	Autocomplete,
	OutlinedInput,
	InputAdornment,
	IconButton,
	Checkbox,
	ListItemText,
	Switch,
	FormLabel,
	Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { SelectValues } from './useSelectField';

type FieldType =
	| 'numeric'
	| 'text'
	| 'textarea'
	| 'select'
	| 'autocomplete'
	| 'password'
	| 'switch';

export interface FieldConfig {
	type: FieldType;
	name: string;
	label: string;
	placeholder?: string;
	options?: { label: string; value: string | number }[];
	style?: React.CSSProperties;
	multiple?: boolean;
	required?: boolean;
	checked?: boolean;
	unit?: string;
}

const useGenerateField = () => {
	const { t } = useTranslation();

	const [showPassword, setShowPassword] = React.useState(false);

	const handleClickShowPassword = () => setShowPassword((show) => !show);

	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
	};

	return (
		config: FieldConfig,
		defaultValue: string | number | (string | number)[] | any,
		error: boolean,
		selectValues?: SelectValues,
		handleChange?: (name: string, event: any, bool?: boolean) => void,
	) => {
		const { type, name, label, placeholder, options, style, multiple, unit, required } = config;

		const fullLabel = (
			<>
				{label}
				{unit && (
					<Box component='span' sx={{ color: '#BDBDBD' }}>
						( {unit} )
					</Box>
				)}
			</>
		);
		switch (type) {
			case 'numeric':
				return (
					<TextField
						key={name}
						type='number'
						name={name}
						label={fullLabel}
						placeholder={placeholder}
						error={error}
						defaultValue={defaultValue}
						style={{ marginBottom: '20px', width: '100%', ...style }}
					/>
				);
			case 'switch':
				const value =
					selectValues && Object.keys(selectValues).includes(name)
						? (selectValues?.[name] as unknown as boolean)
						: defaultValue
							? defaultValue
							: false;

				return (
					<div style={{ marginBottom: '20px', width: '100%', ...style }}>
						<FormLabel id='demo-controlled-radio-buttons-group'>{t(label)}</FormLabel>
						<Switch
							name={name}
							key={name}
							checked={value}
							onChange={
								handleChange && ((event: any) => handleChange(name, event, true))
							}
						/>
					</div>
				);
			case 'text':
				return (
					<TextField
						key={name}
						required={required}
						type='text'
						id={`outlined-basic-${name}`}
						variant='outlined'
						name={name}
						label={fullLabel}
						autoComplete={name}
						placeholder={placeholder}
						error={error}
						defaultValue={defaultValue}
						style={{ marginBottom: '20px', width: '100%', ...style }}
					/>
				);
			case 'textarea':
				return (
					<TextareaAutosize
						key={name}
						name={name}
						aria-label={label}
						placeholder={placeholder}
						defaultValue={defaultValue as string}
						style={{ marginBottom: '20px', width: '100%', ...style }}
					/>
				);
			case 'select':
				const defaultSelectValue = Array.isArray(defaultValue)
					? defaultValue.map((val) => val.id)
					: multiple
						? [] // Si `multiple` est activé, on s'assure d'avoir un tableau
						: defaultValue
							? defaultValue
							: '';

				return (
					<FormControl
						key={name}
						style={{ marginBottom: '20px', width: '100%', ...style }}>
						<InputLabel error={error} id='demo-simple-select-label' required={required}>
							{fullLabel}
						</InputLabel>
						<Select
							labelId={`select-label-${name}`}
							id={`select-${name}`}
							name={name}
							label={fullLabel}
							error={error}
							multiple={multiple}
							value={
								multiple
									? Array.isArray(selectValues?.[name])
										? selectValues?.[name]
										: Array.isArray(defaultSelectValue)
											? defaultSelectValue
											: []
									: selectValues?.[name] || defaultSelectValue || ''
							}
							onChange={handleChange && ((event: any) => handleChange(name, event))}
							placeholder={placeholder}
							renderValue={(selected) => {
								if (Array.isArray(selected)) {
									return selected
										.map((value) => {
											const option = options?.find(
												(opt) => opt.value === value,
											);
											return option ? option.label : '';
										})
										.join(', ');
								}
								const option = options?.find((opt) => opt.value === selected);
								return option ? option.label : '';
							}}>
							<MenuItem value='' disabled>
								<em>{placeholder}</em>
							</MenuItem>
							{options &&
								options.map(({ label, value }, index) => (
									<MenuItem key={index} value={value}>
										{multiple && (
											<Checkbox
												checked={
													Array.isArray(selectValues?.[name])
														? (selectValues?.[name] as any).includes(
																value,
															)
														: Array.isArray(defaultSelectValue)
															? defaultSelectValue.includes(value)
															: false
												}
											/>
										)}
										<ListItemText primary={label} />
									</MenuItem>
								))}
						</Select>
					</FormControl>
				);

			case 'autocomplete':
				return (
					<Autocomplete
						key={name}
						style={{ marginBottom: '25px', width: '100%', ...style }}
						freeSolo
						options={options || []}
						renderInput={(params) => (
							<TextField
								{...params}
								name={name}
								label={label}
								placeholder={placeholder}
								error={error}
								defaultValue={defaultValue}
							/>
						)}
					/>
				);
			case 'password':
				return (
					<FormControl
						variant='outlined'
						style={{ marginBottom: '20px', width: '100%', ...style }}>
						<InputLabel error={error} htmlFor='outlined-adornment-password'>
							{t(label)}
						</InputLabel>
						<OutlinedInput
							name={name}
							error={error}
							id='outlined-adornment-password'
							type={showPassword ? 'text' : 'password'}
							endAdornment={
								<InputAdornment position='end'>
									<IconButton
										aria-label='toggle password visibility'
										onClick={handleClickShowPassword}
										onMouseDown={handleMouseDownPassword}
										edge='end'>
										{showPassword ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							}
							label={t(label)}
							autoComplete='current-password'
						/>
					</FormControl>
				);

			default:
				throw new Error(`Invalid field type: ${type}`);
		}
	};
};

export default useGenerateField;
