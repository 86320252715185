import { Badge, Chip, Avatar, Typography, Box } from '@mui/material';
import {
	LocalFireDepartmentOutlined,
	CheckOutlined,
	CameraOutlined,
	DirectionsRunOutlined,
	WhatshotOutlined,
	DirectionsCar,
	WifiOff,
	VideocamOffOutlined,
	VideocamOutlined,
} from '@mui/icons-material';
import { IDetectionType, IVideoStatusType } from '../types/flux-types';
import theme from '../styles/theme';
import { capitalizeFirstLetter } from './helpers';
import i18n from 'i18next';

export const displayDetectionType = (type: IDetectionType) => {
	switch (type) {
		case 'fire':
			return (
				<Badge
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}>
					<Chip
						avatar={
							<Avatar>
								<LocalFireDepartmentOutlined
									sx={{ color: theme.palette.success.contrastText }}
								/>
							</Avatar>
						}
						label={capitalizeFirstLetter(type)}
					/>
				</Badge>
			);
		case 'smoke':
			return (
				<Badge
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}>
					<Chip
						avatar={
							<Avatar>
								<WhatshotOutlined
									sx={{ color: theme.palette.success.contrastText }}
								/>
							</Avatar>
						}
						label={capitalizeFirstLetter(type)}
					/>
				</Badge>
			);

		case 'drone':
			return (
				<Badge
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}>
					<Chip
						avatar={
							<Avatar>
								<CameraOutlined
									sx={{ color: theme.palette.success.contrastText }}
								/>
							</Avatar>
						}
						label={capitalizeFirstLetter(type)}
					/>
				</Badge>
			);

		case 'ok':
			return (
				<Badge
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}>
					<Chip
						avatar={
							<Avatar>
								<CheckOutlined sx={{ color: theme.palette.success.contrastText }} />
							</Avatar>
						}
						label={capitalizeFirstLetter(type)}
					/>
				</Badge>
			);
		case 'human':
			return (
				<Badge
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}>
					<Chip
						avatar={
							<Avatar>
								<DirectionsRunOutlined
									sx={{ color: theme.palette.success.contrastText }}
								/>
							</Avatar>
						}
						label={capitalizeFirstLetter(type)}
					/>
				</Badge>
			);
		case 'vehicle':
			return (
				<Badge
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}>
					<Chip
						avatar={
							<Avatar>
								<DirectionsCar sx={{ color: theme.palette.success.contrastText }} />
							</Avatar>
						}
						label={capitalizeFirstLetter(type)}
					/>
				</Badge>
			);
		default:
			return type ? capitalizeFirstLetter(type) : '-';
	}
};

export const displayVideoStatus = (type: IVideoStatusType) => {
	switch (type) {
		case 'NOT_PLAYING':
			return (
				<Badge
					color='error'
					variant='dot'
					overlap='circular'
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}>
					<Box
						sx={{
							padding: '5px',
							borderRadius: 100,
							backgroundColor: '#BDBDBD',
							display: 'flex',
						}}>
						<VideocamOffOutlined
							htmlColor='#fff'
							sx={{ width: '20px', height: '20px' }}
						/>
					</Box>
				</Badge>
			);
		case 'PLAYING':
			return (
				<Badge
					color='success'
					variant='dot'
					overlap='circular'
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}>
					<Box
						sx={{
							padding: '5px',
							borderRadius: 100,
							backgroundColor: '#BDBDBD',
							display: 'flex',
						}}>
						<VideocamOutlined htmlColor='#fff' sx={{ width: '20px', height: '20px' }} />
					</Box>
				</Badge>
			);

		case 'NO_INPUT_STREAM':
			return <WifiOff htmlColor='#9E9E9E' sx={{ width: '20px', height: '20px' }} />;
		case 'WAITING_PROCESSING_SERVER':
			return (
				<Typography color={theme.palette.error.main}>
					{i18n.t('flux.Démarrage en cours')}
				</Typography>
			);

		default:
			return null;
	}
};
