import axios from 'axios';

export const getTokenFromLocalStorage = (): string => {
	if (typeof window !== 'undefined') {
		const value: string | null = localStorage.getItem('token');
		return value ? value : '';
	}
	return '';
};

export const http = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	timeout: 60000,
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json',
	},
});

export const httpSecured = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	timeout: 60000,
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json',
	},
});

export const httpSecuredWithoutApi = axios.create({
	baseURL: process.env.REACT_APP_URL,
	timeout: 60000,
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json',
	},
});

// Ajoutez un intercepteur pour ajouter le token aux requêtes sortantes
httpSecured.interceptors.request.use(
	(config) => {
		const token = getTokenFromLocalStorage();
		if (token) {
			config.headers.Authorization = `Token ${token}`;
		}
		if (config.data instanceof FormData) {
			delete config.headers['Content-Type'];
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	},
);
export default httpSecured;

httpSecuredWithoutApi.interceptors.request.use(
	(config) => {
		const token = getTokenFromLocalStorage();
		if (token) {
			config.headers.Authorization = `Token ${token}`;
		}
		if (config.data instanceof FormData) {
			delete config.headers['Content-Type'];
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	},
);

export const API_ENDPOINT = {
	DASHBOARD: {
		GET_KPI: '/dashboard/kpi/',
		EXPORT_CSV: '/dashboard/csv/',
		GET_GPU: '/dashboard/gpu/',
	},
	ORGANISATION: {
		ADD_USER_TO_ORGANISATION: '/organisation/add_user/',
		REMOVE_USER_FROM_ORGANISATION: '/organisation/remove_user/',
		ADD_VIDEO: '/organisation/add_video/',
		GET_ORGANISATIONS: '/organisation/all/',
		CREATE_ORGANISATION: '/organisation/create/',
		DELETE_ORGANISATION: '/organisation/delete/',
		GET_ORGANISATION: '/organisation/get/',
		UPDATE_ORGANISATION: '/organisation/update/',
		ACTIVE_STREAM: '/organisation/active-stream-infos/',
		PROCESS_STREAM: '/organisation/process-n-stream/',
	},
	USER: {
		LOGIN: '/user/login/',
		LOGOUT: '/user/logout/',
		SIGNUP_ADMIN: '/user/signup-admin/',
		SIGNUP: '/user/signup/',
		SEARCH_USER: '/user/search-user/',
		SIGNUP_ORGANISATION: '/user/signup-organisation/',
		GET_USERS: '/user/all/',
		DELETE_USER: '/user/delete/',
		UPDATE_USER: '/user/update/',
	},
	INSTANCE: '/computing-instance/',
	HISTORY: {
		GET_HISTORY: '/history/all/',
		DELETE_HISTORY: '/history/delete/',
	},
	FLUX: {
		GET_FLUX: '/video/all/',
		DELETE_FLUX: '/video/delete/',
		CREATE_FLUX: '/video/create/',
		UPDATE_FLUX: '/video/update/',
		GET_ONE_FLUX: '/video/get/',
		EXPORT_CSV: '/video/csvHistory/',
		PLAY: '/video/play/',
		STOP: '/video/stop/',
		PAUSE: '/video/pause/',
		STATUS: '/video/get_status/',
		SUPERVISOR_START: '/supervisor/invocation/start/',
		SUPERVISOR_STOP: '/supervisor/invocation/stop/',
		SUPERVISOR_STATUS: '/supervisor/invocation/status/',
	},
	NOTIFICATION: {
		GET_NOTIFICATIONS: '/notification/all/',
		GET_NOTIFICATION: '/notification/get/',
		SET_TO_READ: '/notification/setToRead/',
	},
};
