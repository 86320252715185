import { createModel } from '@rematch/core';
import { RootModel } from '..';
import { IAuthState, IAuthUser, ILogin } from '../../types/auth-types';
import { API_ENDPOINT, http } from '../../services/http';
import { redirect } from 'react-router-dom';
import { authPages } from '../../routes/menu';

export const auth = createModel<RootModel>()({
	state: {
		email: '',
		token: '',
		id: null,
		open: true,
		role: 'user',
	} as IAuthState,
	reducers: {
		setUser: (state: IAuthState, payload: IAuthUser) => {
			return {
				...state,
				email: payload.email,
				token: payload.token,
				id: payload.id,
				role: payload.role,
			};
		},
		setLanguage: (state: IAuthState, language: string) => {
			return { ...state, language };
		},
		setOpen: (state: IAuthState, open: boolean) => {
			return { ...state, open };
		},
	},
	effects: (dispatch) => ({
		async login(payload: ILogin) {
			const { email } = payload;
			try {
				const response = await http.post(API_ENDPOINT.USER.LOGIN, payload);

				if (response.data.token) {
					localStorage.setItem('token', response.data.token);
					dispatch.auth.setUser({
						email,
						token: response.data.token,
						id: response.data.id,
						role: response.data.role,
					});
					return response;
				}
			} catch (error: any) {
				console.error(error);
				localStorage.removeItem('token');
				return error.response.data;
			}
		},
		async logout(fromLogout = false) {
			try {
				const { status } = await http.post(API_ENDPOINT.USER.LOGOUT);
				if (status === 200) {
					localStorage.clear();
					dispatch.auth.setUser({ email: '', token: '', id: null } as IAuthUser);
					redirect(authPages.login.path);
					if (!fromLogout) {
						dispatch.alerts.setAlert({
							open: true,
							severity: 'error',
							message: 'Votre session a expiré.',
						});
					}
				}
			} catch (error) {
				console.error(error);
				return error;
			}
		},
	}),
});
