import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { RootState } from '../../../store/store';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useFields = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { organisations } = useSelector((state: RootState) => state.organisations);
	const { token } = useSelector((state: RootState) => state.auth);

	const type_cam = [
		{ name: 'RGB', id: 1 },
		{ name: 'Thermal', id: 2 },
		{ name: 'Splitted', id: 3 },
	];

	React.useEffect(() => {
		if (token) {
			dispatch.organisations.getAll();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [token]);

	const fields = [
		{
			type: 'text',
			name: 'name',
			label: t('flux.Titre'),
			placeholder: t('flux.Ex: Flux'),
			required: true,
		},
		{
			type: 'select',
			name: 'organisations',
			label: t('flux.Organisation(s)'),
			multiple: true,
			required: true,
			placeholder: t('flux.Choissisez une organisation'),
			options: organisations.map((organisation) => {
				return { label: organisation.name, value: organisation.id };
			}),
		},
		{
			type: 'select',
			name: 'camera_type',
			label: t('flux.camera_type'),
			multiple: false,
			placeholder: t('flux.camera_type'),
			options: type_cam.map((camera) => {
				return { label: t(`flux.${camera.name}`), value: camera.name };
			}),
			style: { marginBottom: '35px' },
			required: true,
		},
		{
			type: 'switch',
			name: 'is_autonomous_drone',
			label: t('flux.drone_autonome'),
			required: false,
			ckeck: false,
		},
		{
			type: 'text',
			name: 'serial_number',
			label: t('flux.Serial_number'),
			placeholder: t('flux.Serial_number'),
			required: false,
			dependantField: 'is_autonomous_drone',
		},
	];

	const typeCamSelect = {
		type: 'select',
		name: 'camera_type',
		label: t('flux.camera_type'),
		multiple: false,
		placeholder: t('flux.camera_type'),
		options: type_cam.map((camera) => {
			return { label: t(`flux.${camera.name}`), value: camera.name };
		}),
		style: { marginBottom: '0px' },
	};

	return { fields, typeCamSelect };
};

export default useFields;
