import { Stack, Typography } from '@mui/material';
import DataTable from '../../../components/datatable/Datatable';
import { headCellsLiveStream } from '../constants/columns';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';

const LiveStreams = () => {
	const { t } = useTranslation();
	const { active_stream } = useSelector((state: RootState) => state.flux);
	return (
		<>
			<Stack direction='row' justifyContent='space-between' sx={{ marginBottom: '35px' }}>
				<Typography variant='h1'>{t('flux.Live streams')}</Typography>
			</Stack>
			<DataTable
				columns={headCellsLiveStream}
				data={active_stream}
				defaultSortId='organisation'
				translation='flux'
			/>
		</>
	);
};

export default LiveStreams;
