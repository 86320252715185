import { Button } from '@mui/material';
import CustomDrawer from './CustomDrawer';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface IDeleteDrawerProps {
	open: boolean;
	setOpen: () => void;
	data: { id: number; name: string };
	type?: string;
	handleDelete: (id: number) => void;
}

const DeleteDrawer: FC<IDeleteDrawerProps> = ({ open, setOpen, data, type, handleDelete }) => {
	const { t } = useTranslation();
	return (
		<CustomDrawer
			open={open}
			setOpen={setOpen}
			text={t('common.Souhaitez vous confirmer la suppression')}
			title={t('common.Confirmer la suppression ?')}
			>
			<Button
				disableRipple
				variant='custom'
				sx={{
					marginBottom: '60px',
					'&:hover': {
						cursor: 'default',
						backgroundColor: '#FFF',
					},
				}}>
				{type ? t(type) : undefined} {data?.name}
			</Button>
			<Button
				onClick={() => handleDelete(data.id)}
				variant='contained'
				color='error'
				sx={{ margin: '15px 0', width: '100%' }}>
				{t('common.Supprimer')}
			</Button>
		</CustomDrawer>
	);
};

export default DeleteDrawer;
