import { createModel } from '@rematch/core';
import { RootModel } from '..';
import { API_ENDPOINT, httpSecured } from '../../services/http';
import { IInstance } from '../../types/instances-types';

export const instances = createModel<RootModel>()({
	state: {
		instances: [] as IInstance[],
		instance: {} as IInstance,
	},
	reducers: {
		set: (state, payload, subject) => {
			return { ...state, [subject]: payload };
		},
	},
	effects: (dispatch) => ({
		async getAll() {
			try {
				const { data } = await httpSecured.get(API_ENDPOINT.INSTANCE);
				if (data) {
					dispatch.instances.set(data, 'instances');
					return data;
				}
			} catch (error: any) {
				console.error(error);
				if (error.response?.status === 401) {
					dispatch.auth.logout();
				}
			}
		},
		async getOne(id: number) {
			try {
				const { data } = await httpSecured.get(`${API_ENDPOINT.INSTANCE}${id}`);
				if (data) {
					dispatch.instances.set(data, 'instance');
					return data;
				}
			} catch (error: any) {
				console.error(error);
				if (error.response?.status === 401) {
					dispatch.auth.logout();
				}
			}
		},
		async create(payload) {
			try {
				const { data } = await httpSecured.post(API_ENDPOINT.INSTANCE, payload);
				if (data) {
					dispatch.alerts.setAlert({
						open: true,
						severity: 'success',
						message: "L'instance a été ajoutée avec succès.",
					});
					this.getAll();
					return true;
				}
			} catch (error: any) {
				console.error(error);
				if (error.response?.status === 401) {
					dispatch.auth.logout();
				}
				dispatch.alerts.setAlert({
					open: true,
					severity: 'error',
					message:
						typeof error.response.data.error == 'string'
							? error.response.data.error
							: "Une erreur est survenue lors de la création de l'instance.",
				});
				return false;
			}
		},
		async update(payload) {
			try {
				const { data } = await httpSecured.put(
					`${API_ENDPOINT.INSTANCE}${payload.id}/`,
					payload,
				);
				if (data) {
					dispatch.alerts.setAlert({
						open: true,
						severity: 'success',
						message: "L'instance a été modifiée avec succès.",
					});
					this.getAll();
					return true;
				}
			} catch (error: any) {
				console.error(error);
				if (error.response?.status === 401) {
					dispatch.auth.logout();
				}
				dispatch.alerts.setAlert({
					open: true,
					severity: 'error',
					message:
						typeof error.response.data.error == 'string'
							? error.response.data.error
							: error.response.data.message ||
								"Une erreur est survenue lors de la modification de l'instance.",
				});
			}
		},
		async delete(id) {
			try {
				await httpSecured.delete(`${API_ENDPOINT.INSTANCE}${id}`);

				dispatch.alerts.setAlert({
					open: true,
					severity: 'success',
					message: "L'instance a été supprimée avec succès.",
				});
				this.getAll();
				return true;
			} catch (error: any) {
				console.error(error);
				if (error.response?.status === 401) {
					dispatch.auth.logout();
				}
				dispatch.alerts.setAlert({
					open: true,
					severity: 'error',
					message:
						typeof error.response.data.error == 'string'
							? error.response.data.error
							: error.response.data.message ||
								"Une erreur est survenue lors de la suppression de l'instance.",
				});
				return false;
			}
		},
	}),
});
